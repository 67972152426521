import * as React from "react"
import Page from "../components/page";
import Header from "../components/header";
import {BlogContainer} from "../components/blog/container";
import {BlogMDWrapper} from "../components/blog/md-wrapper";
import ReactMarkdown from "react-markdown";
import {MarkdownViewer} from "../components/markdown-viewer";

const german = `
### Information according to § 5 German TMG:

Operator and responsible for the content: **Unaty Technologies GmbH, Unertsltraße 27, 80803 Munich, Germany**.

Represented by: **Sean McCall**

**Data protection:**

If you have any questions or comments regarding data protection or the assertion of data subject rights, you can reach our data protection team at the e-mail address dataprivacy@getunaty.com. Please include "Personal - Data Protection" in any submissions regarding this matter.

Contact: [hey@getunaty.com](mailto:hey@getunaty.com)

Commercial Register: **HRB 233951, Commercial Register B of the Munich District Court**

Sales tax identification number: **DE 312 532 117** (according to §27 German sales tax law)

Realization of the website by: **Unaty Technologies GmbH**, address as above

**Use of our contact data:**

The use of contact data published in the imprint by third parties for the purpose of sending unsolicited advertising and information materials is hereby expressly prohibited. The operators of the pages expressly reserve the right to take legal action in the event of the unsolicited sending of advertising information, such as through spam mails.

**Availability of the website:**

We will make every effort to offer our service and the contents of the website for retrieval without interruption as far as possible. However, even with all due care, downtimes cannot be ruled out. The service may be changed or discontinued at any time. We cannot accept any liability for interruptions or other disruptions caused by files that have not been created without errors or formats that have not been structured without errors.


---------

### Angaben gemäß § 5 TMG:

Betreiber und verantwortlich für den Inhalt: **Unaty Technologies GmbH, Unertsltraße 27, 80803 München, Deutschland**

Geschäftsführung: **Sean McCall**

**Datenschutz:**

Bei Fragen oder Anmerkungen zum Thema Datenschutz oder zur Geltendmachung von Betroffenenrechten erreichen Sie unser Datenschutz-Team unter der E-Mail-Adresse dataprivacy@getunaty.com. Einsendungen in dieser Angelegenheit bitte mit dem Vermerk „Persönlich - Datenschutz“.

Kontakt: [hey@getunaty.com](mailto:hey@getunaty.com)

Handelsregister: **HRB 233951, Handelsregister B des Amtsgerichts München**

Umsatzsteuer-Identifikationsnummer: **DE 312 532 117** (gemäß §27 a Umsatzsteuergesetz)

Realisierung der Website durch: **Unaty Technologies GmbH**, Anschrift wie oben

**Nutzung unserer Kontaktdaten:**

Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.

**Verfügbarkeit der Website:**

Wir werden uns bemühen, unseren Dienst und die Inhalte der Website möglichst unterbrechungsfrei zum Abruf anzubieten. Auch bei aller Sorgfalt können aber Ausfallzeiten nicht ausgeschlossen werden. Das Angebot kann jederzeit geändert oder eingestellt werden. Für durch nicht fehlerfrei angelegte Dateien oder nicht fehlerfrei strukturierte Formate bedingte Unterbrechungen oder anderweitige Störungen können wir keine Gewähr übernehmen.
              
`;

export default function ImprintPage() {
    return (
        <Page menu>
            <Header title='Imprint' />

            <div>
<BlogContainer>
    <BlogMDWrapper>
        <MarkdownViewer source={german}></MarkdownViewer>
    </BlogMDWrapper>
</BlogContainer>
            </div>

        </Page>
    )
};